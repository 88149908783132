import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useBlackSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = []

  const ui = {}

  return {
    ui,
    stateOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useBlackList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '黑名單帳號', key: 'account', sortable: true, searchable: true, select: true,
    },
    {
      label: 'IP位置', key: 'ip', sortable: true, searchable: true, select: true,
    },
    {
      label: '原因', key: 'reason', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchState = ref(null)
  const blankBlackData = {
    id: null,
    account: null,
    ip: null,
    reason: null,
    created_at: null,
    updated_at: null,
  }

  const setBlackDelete = (...arg) => store.dispatch('admin-black/setBlackDelete', ...arg)
  const setBlackCreate = (...arg) => store.dispatch('admin-black/setBlackCreate', ...arg)
  const setBlackUpdate = (...arg) => store.dispatch('admin-black/setBlackUpdate', ...arg)

  const getBlackListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    store.dispatch('admin-black/getBlackList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankBlackData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    timeRange,
    blankBlackData,
    refetchData,
    refetchTable,

    setBlackDelete,
    setBlackCreate,
    setBlackUpdate,
    getBlackListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
